<template>
  <v-card class="mt-4 mx-auto">
    <v-sheet
      class="v-sheet--offset mx-auto"
      color="success"
      elevation="12"
      max-width="calc(100% - 32px)"
    >
      <v-card
        dark
        color="color_green"
      >
        <v-card-title> ADMIN </v-card-title>
      </v-card>
    </v-sheet>
    <v-card-text class="pt-0">
      <v-data-table
        id="admin-table"
        :headers="admin.headers"
        :items="admin.admins"
        :search="search"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn
              color="color_green"
              dark
              class="mb-2"
              @click="newAdminButtonEvent"
            >
              New Admin
            </v-btn>
          </v-toolbar>
        </template>
        <template
          v-slot:[`item.c_description`]="{ item }"
          class="d-grid"
        >
          <span
            class="d-inline-block text-truncate"
            style="max-width: 30em"
          >
            {{ item.c_description }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <delete
            :id="item.username"
            @deleted="deleteItem(item.username)"
          />
        </template>
        <template v-slot:no-data>
          <span color="primary">No data to show you</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    delete: () => import('@/components/utils/Delete')
  },

  data() {
    return {
      search: '',
      cleanItem: {
        username: '',
        _name: '',
        surname: '',
        email: ''
      },
      loading: false
    };
  },
  computed: {
    ...mapState(['admin']),
    target() {
      const value = 9999;

      return value;
    },
    options() {
      return {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      };
    }
  },

  async created() {
    this.loading = true;
    await this.storeAdmins();
    this.loading = false;
  },

  methods: {
    ...mapActions(['storeAdmins', 'deleteTableAdmin']),
    newAdminButtonEvent() {
      this.$emit('openDialog');
      this.$emit('toParent');
      this.$vuetify.goTo(this.target, this.options);
    },
    emitTableItem(item) {
      this.$emit('toParent', item);
    },
    async deleteItem(username) {
      this.$emit('closeDialog');
      await this.deleteTableAdmin(username);
    }
  }
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
